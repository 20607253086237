<template>
  <div>
    <SettingsContentItem v-model="form.last_name" :button="true" label="Nom" />
    <SettingsContentItem
      v-model="form.first_name"
      :button="true"
      label="Prénom"
    />
    <SettingsContentItem
      v-model="form.email"
      type="email"
      label="Adresse contact"
    />
    <SettingsContentItem
      v-model="form.phone"
      :button="true"
      label="Numéro contact"
    />

    <SettingsContentItem label="Statut du compte">
      <div class="flex-1 flex flex-row-reverse mr-6">
        <div>
          <Toggle :checked="form.active" @input="changeStatus" />
        </div>
      </div>
    </SettingsContentItem>

    <Snackbar v-model="showSnackbar" v-bind="snackbarProps"></Snackbar>

    <Modal
      v-show="showModal"
      :type="form.active ? 'success' : 'danger'"
      :title="
        form.active
          ? $t('pages.settings.general.enable_title')
          : $t('pages.settings.general.disable_title')
      "
      :actions="{
        cancelVisible: true,
        cancelLabel: 'Annuler',
        actionVisible: true,
        actionLabel: form.active
          ? $t('pages.settings.general.enable_action')
          : $t('pages.settings.general.disable_action'),
        backdropClickEnabled: false
      }"
      @action="save()"
      @cancel="cancelActive()"
    >
      {{
        form.active
          ? $t("pages.settings.general.enable_label")
          : $t("pages.settings.general.disable_label")
      }}
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import SettingsContentItem from "@/components/settings/SettingsContentItem.vue";

export default {
  name: "SettingsGeneralContent",

  components: {
    SettingsContentItem
  },

  data() {
    return {
      showSnackbar: false,
      showModal: false,
      isEditing: false,
      form: {
        last_name: "",
        first_name: "",
        email: "",
        phone: "",
        active: false
      },
      hasError: false
    };
  },

  computed: {
    snackbarProps() {
      let props = {
        variant: "success",
        text: "Vos paramètres sont sauvegardés !",
        action: "OK",
        x: "center",
        y: "top",
        timeout: 5
      };

      if (this.hasError) {
        props = {
          ...props,
          variant: "error",
          text: "Erreur ! Impossible de sauvegarder vos paramètres"
        };
      }

      return props;
    },

    ...mapState("user", ["user"])
  },

  methods: {
    ...mapActions("user", ["logout"]),

    async save() {
      try {
        await this.$store.dispatch("user/update", {
          id: this.user.id,
          body: this.form
        });
        this.hasError = false;
        this.showSnackbar = true;
        this.showModal = false;

        if (!this.form.active) {
          this.logout().then(() => {
            window.location.replace(
              process.env.VUE_APP_CMN_URL + "/deconnexion"
            );
          });
        }
      } catch (error) {
        this.hasError = true;
        this.showSnackbar = true;
        this.setDefaultUser();
        this.showModal = false;
      }
    },

    cancelActive() {
      this.form.active = !this.form.active;
      this.showModal = false;
    },

    setDefaultUser() {
      this.form.last_name = this.user.last_name;
      this.form.first_name = this.user.first_name;
      this.form.email = this.user.email;
      this.form.phone = this.user.phone;
      this.form.active = this.user.active;
    },

    changeStatus() {
      this.form.active = !this.form.active;
      this.showModal = true;
    }
  },

  mounted() {
    this.setDefaultUser();
  }
};
</script>

<template>
  <div>
    <SettingsPhoneTimeSlots :show="showPhoneTimeSlot" />
    <SettingsContentItem label="Numéro de Téléphone">
      <div class="flex-1 flex flex-row-reverse mr-6">
        <div>
          <Toggle
            :checked="form.public_phone"
            :disabled="!canChangePublicPhone"
            @input="changePublicPhone"
          />
        </div>
      </div>
    </SettingsContentItem>

    <label
      v-if="this.user.phone.length > 0"
      class="
      flex flex-col
      items-start
      py-2
      font-sans
      lg:flex-row
      lg:items-center
      lg:h-12
      border-b border-gray-cloud
    "
    >
      <div class="w-64 text-base text-left text-gray-smoke">
        Créneaux de disponibilité
      </div>
      <div
        class="
        flex-1 flex flex-row
        items-center
        h-full
        text-sm text-left text-gray-elephant ml-4
      "
      >
        {{ phoneTimeSlotLabel }}
      </div>
      <Button appearance="invert" size="sm" @click="showPhoneTimeSlot = true"
        >Modifier</Button
      >
    </label>

    <label
      class="
      flex flex-col
      items-start
      py-2
      font-sans
      lg:flex-row
      lg:items-center
      lg:h-12
      border-b border-gray-cloud
    "
    >
      <div class="w-64 text-base text-left text-gray-smoke">
        Politique de Confidentialité
      </div>
      <div
        class="
        flex-1 flex flex-row
        items-center
        h-full
        text-sm text-left text-gray-elephant ml-4
      "
      ></div>
      <a :href="cguUrl" target="blank">
        <Button appearance="invert" size="sm">Consulter</Button>
      </a>
    </label>

    <Snackbar v-model="showSnackbar" v-bind="snackbarProps"></Snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import SettingsContentItem from "@/components/settings/SettingsContentItem.vue";
import SettingsPhoneTimeSlots from "@/components/settings/SettingsPhoneTimeSlots.vue";

export default {
  name: "SettingsGeneralContent",

  components: {
    SettingsContentItem,
    SettingsPhoneTimeSlots
  },

  data() {
    return {
      showSnackbar: false,
      showPhoneTimeSlot: false,
      form: {
        public_phone: "",
        public_sale: ""
      },
      hasError: false
    };
  },

  computed: {
    canChangePublicPhone() {
      return !!this.user.phone;
    },

    canChangePublicSale() {
      return (
        !_.isNil(this.selectedProject) &&
        this.selectedProject.sale !== undefined
      );
    },

    phoneTimeSlotLabel() {
      let label = "";
      if (
        this.user.phone_time_slots !== null &&
        this.user.phone_time_slots.length > 0
      ) {
        let slots = this.user.phone_time_slots[0];
        if (slots.week.day || slots.week.midday || slots.week.evening) {
          label = "Semaine";
        }
        if (
          slots.saturday.day ||
          slots.saturday.midday ||
          slots.saturday.evening
        ) {
          label += (label != "" ? " & " : "") + "Samedi";
        }
      }

      return label != "" ? label : "Aucune";
    },

    cguUrl() {
      return process.env.VUE_APP_CMN_URL + "/politique-de-confidentialite";
    },

    snackbarProps() {
      let props = {
        variant: "success",
        text: "Vos paramètres sont sauvegardés !",
        action: "OK",
        x: "center",
        y: "top",
        timeout: 5
      };

      if (this.hasError) {
        props = {
          ...props,
          variant: "error",
          text: "Erreur ! Impossible de sauvegarder vos paramètres"
        };
      }

      return props;
    },
    ...mapState("user", ["user"]),
    ...mapState("project", ["selectedProject"])
  },

  methods: {
    async save() {
      try {
        await this.$store.dispatch("user/update", {
          id: this.user.id,
          body: this.form
        });
        this.hasError = false;
        this.showSnackbar = true;
      } catch (error) {
        this.hasError = true;
        this.showSnackbar = true;
        this.setDefaultUser();
      }
    },

    setDefaultUser() {
      this.form.last_name = this.user.last_name;
      this.form.first_name = this.user.first_name;
      this.form.email = this.user.email;
      this.form.phone = this.user.phone;
      this.form.public_phone = this.user.public_phone;
      this.form.public_sale = this.user.public_sale;
    },

    changePublicPhone() {
      this.form.public_phone = !this.form.public_phone;
      this.save();
    },

    changePublicSale() {
      this.form.public_sale = !this.form.public_sale;
      this.save();
    }
  },

  mounted() {
    this.setDefaultUser();
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 flex flex-col"},[_c('div',{staticClass:"mt-8 lg:hidden"},_vm._l((Object.values(_vm.pages)),function(page,idx){return _c('div',{key:idx},[_c('router-link',{attrs:{"to":page.menu.to,"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('SettingsMenuItem',{attrs:{"active":isExactActive,"href":href,"icon-name":page.menu.icon,"text":page.menu.text,"is-mobile":true},nativeOn:{"click":function($event){return navigate.apply(null, arguments)}}})]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(page.showContent()),expression:"page.showContent()"}],staticClass:"p-6 bg-gray-light"},[_c('SettingsContent',_vm._b({},'SettingsContent',page.content,false),[(!_vm.$route.params.setting)?_c('SettingsGeneralContent'):(_vm.$route.params.setting === 'securite')?_c('SettingsPasswordContent'):(_vm.$route.params.setting === 'confidentialite')?_c('SettingsConfidentialityContent'):_c('span',[_vm._v(_vm._s(_vm.$route.params.setting || "général"))])],1)],1)],1)}),0),_c('div',{staticClass:"hidden lg:flex flex-1 flex-row"},[_c('div',{staticClass:"w-64 pt-2 flex flex-col flex-shrink-0"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.goBack.apply(null, arguments)}}},[_c('Button',{attrs:{"shape":"pills","color":"secondary","size":"sm"}},[_c('Icon',{staticClass:"mr-2",attrs:{"name":"chevron-double","size":"sm"}}),_vm._v(" "+_vm._s(_vm.$t("pages.settings.back")))],1)],1),_c('h3',{staticClass:"pt-4 font-lato font-black text-left text-3xl text-gray-smoke"},[_vm._v(" Paramètres ")]),_vm._l((_vm.pages),function(page,idx){return _c('router-link',{key:idx,attrs:{"to":page.menu.to,"replace":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('SettingsMenuItem',{attrs:{"active":isExactActive,"href":href,"icon-name":page.menu.icon,"text":page.menu.text},nativeOn:{"click":function($event){return navigate.apply(null, arguments)}}})]}}],null,true)})})],2),_c('div',{staticClass:"w-full pt-17 pl-8 border-l-2 border-gray-elephant"},[_c('SettingsContent',_vm._b({},'SettingsContent',_vm.pages[_vm.$route.params.setting || 'general'].content,false),[(!_vm.$route.params.setting)?_c('SettingsGeneralContent'):(_vm.$route.params.setting === 'securite')?_c('SettingsPasswordContent'):(_vm.$route.params.setting === 'confidentialite')?_c('SettingsConfidentialityContent'):_c('span',[_vm._v(_vm._s(_vm.$route.params.setting || "général"))])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <a
    :href="href"
    class="flex flex-row items-center h-12 px-4 py-3"
    :class="{
      'font-lato font-bold text-xl text-gray-dark': isMobile,
      'font-sans font-normal text-base text-gray-smoke border-r-8 border-transparent hover:border-secondary-20': !isMobile,
      'font-black': active,
      'text-secondary bg-gradient-to-r from-haze-start to-haze-stop border-current':
        !isMobile && active
    }"
  >
    <Icon
      v-if="icon"
      :name="icon"
      class="mr-3"
      :class="{ 'text-gray-elephant': !isMobile && !active }"
    />
    <span>{{ text }}</span>
    <Icon
      name="chevron"
      size="md"
      class="ml-auto transform"
      :class="[{ hidden: !isMobile }, active ? 'rotate-0' : '-rotate-90']"
    />
  </a>
</template>

<script>
const ICONS = {
  settings: "cog",
  verified: "shield-check",
  social: "globe",
  notification: "bell"
};

export default {
  name: "SettingsMenuItem",

  props: {
    href: {
      type: String
    },

    active: {
      type: Boolean,
      default: false
    },

    iconName: {
      type: String
    },

    text: {
      type: String
    },

    isMobile: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    icon() {
      return ICONS[this.iconName];
    }
  }
};
</script>

<template>
  <div class="flex-1 flex flex-col">
    <!-- mobile -->
    <div class="mt-8 lg:hidden">
      <div v-for="(page, idx) in Object.values(pages)" :key="idx">
        <router-link
          :to="page.menu.to"
          replace
          v-slot="{ href, navigate, isExactActive }"
        >
          <SettingsMenuItem
            :active="isExactActive"
            :href="href"
            @click.native="navigate"
            :icon-name="page.menu.icon"
            :text="page.menu.text"
            :is-mobile="true"
          />
        </router-link>
        <div v-show="page.showContent()" class="p-6 bg-gray-light">
          <SettingsContent v-bind="page.content">
            <SettingsGeneralContent v-if="!$route.params.setting" />
            <SettingsPasswordContent
              v-else-if="$route.params.setting === 'securite'"
            />
            <SettingsConfidentialityContent
              v-else-if="$route.params.setting === 'confidentialite'"
            />
            <span v-else>{{ $route.params.setting || "général" }}</span>
          </SettingsContent>
        </div>
      </div>
    </div>

    <!-- desktop -->
    <div class="hidden lg:flex flex-1 flex-row">
      <div class="w-64 pt-2 flex flex-col flex-shrink-0">
        <a href="#" @click.stop="goBack">
          <Button shape="pills" color="secondary" size="sm"
            ><Icon name="chevron-double" size="sm" class="mr-2" />
            {{ $t("pages.settings.back") }}</Button
          >
        </a>
        <h3
          class="pt-4 font-lato font-black text-left text-3xl text-gray-smoke"
        >
          Paramètres
        </h3>

        <router-link
          v-for="(page, idx) in pages"
          :key="idx"
          :to="page.menu.to"
          replace
          v-slot="{ href, navigate, isExactActive }"
        >
          <SettingsMenuItem
            :active="isExactActive"
            :href="href"
            @click.native="navigate"
            :icon-name="page.menu.icon"
            :text="page.menu.text"
          />
        </router-link>
      </div>

      <div class="w-full pt-17 pl-8 border-l-2 border-gray-elephant">
        <SettingsContent
          v-bind="pages[$route.params.setting || 'general'].content"
        >
          <SettingsGeneralContent v-if="!$route.params.setting" />
          <SettingsPasswordContent
            v-else-if="$route.params.setting === 'securite'"
          />
          <SettingsConfidentialityContent
            v-else-if="$route.params.setting === 'confidentialite'"
          />
          <span v-else>{{ $route.params.setting || "général" }}</span>
        </SettingsContent>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsMenuItem from "@/components/settings/SettingsMenuItem.vue";
import SettingsContent from "@/components/settings/SettingsContent.vue";

import SettingsGeneralContent from "@/components/settings/SettingsGeneralContent.vue";
import SettingsPasswordContent from "@/components/settings/SettingsPasswordContent.vue";
import SettingsConfidentialityContent from "@/components/settings/SettingsConfidentialityContent.vue";

export default {
  name: "Settings",
  components: {
    SettingsMenuItem,
    SettingsContent,
    SettingsGeneralContent,
    SettingsPasswordContent,
    SettingsConfidentialityContent
  },
  data: function() {
    return {
      pages: {
        general: {
          menu: {
            icon: "settings",
            text: "Général",
            to: {
              params: {
                setting: null
              }
            }
          },
          content: {
            title: "Paramètres généraux du compte",
            subtitle: " "
          },
          showContent: () => !this.$route.params.setting
        },
        securite: {
          menu: {
            icon: "verified",
            text: "Securité",
            to: {
              params: {
                setting: "securite"
              }
            }
          },
          content: {
            title: "Paramètres de sécurité et de connexion",
            subtitle:
              "Nous vous conseillons d'opter pour un mot de passe sûr, que vous n'utiliserez nulle part ailleurs <strong>(8 caractères minimum)</strong>."
          },
          showContent: () => this.$route.params.setting === "securite"
        },
        confidentialite: {
          menu: {
            icon: "social",
            text: "Confidentialité",
            to: {
              params: {
                setting: "confidentialite"
              }
            }
          },
          content: {
            title:
              "Quelles informations sont consultables par les professionnels ?",
            subtitle:
              "En vous inscrivant sur Cherchemonnid, votre email est obligatoirement transmis aux professionnels."
          },
          showContent: () => this.$route.params.setting === "confidentialite"
        }
      }
    };
  },

  methods: {
    goBack() {
      this.$router.push({ name: "Projects" });
    }
  }
};
</script>

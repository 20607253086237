<template>
  <div>
    <SettingsContentItem
      ref="passwordInput"
      v-model="form.password"
      :button="!isEditing"
      type="password"
      label="Mot de passe"
      placeholder="Nouveau mot de passe"
      class="border-b border-gray-cloud"
    />
    <SettingsContentItem
      ref="passwordConfirmationInput"
      v-model="form.password_confirmation"
      :disabled="false"
      :visible="isEditing"
      :button="true"
      :canValidate="canValidate"
      type="password"
      label="Confirmation"
    />

    <Snackbar v-model="showSnackbar" v-bind="snackbarProps"></Snackbar>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import SettingsContentItem from "@/components/settings/SettingsContentItem.vue";

export default {
  name: "SettingsPasswordContent",

  components: {
    SettingsContentItem
  },

  data() {
    return {
      showSnackbar: false,
      isEditing: false,
      form: {
        password: "motdepasse",
        password_confirmation: ""
      },
      hasError: false
    };
  },

  computed: {
    snackbarProps() {
      let props = {
        variant: "success",
        text: "Votre mot de passe à été modifié !",
        action: "OK",
        x: "center",
        y: "top",
        timeout: 5
      };

      if (this.hasError) {
        props = {
          ...props,
          variant: "error",
          text: "Erreur ! Impossible de modifier votre mot de passe"
        };
      }

      return props;
    },

    canValidate() {
      return (
        this.form.password === this.form.password_confirmation &&
        this.form.password.length >= 8
      );
    },

    ...mapState("user", ["user"]),
    ...mapGetters("user", ["gtmProfile"])
  },

  methods: {
    reset() {
      this.form = {
        email: this.user.email,
        password: "",
        password_confirmation: ""
      };
    },

    async save() {
      try {
        this.$gtm.trackEvent({
          event: "user-password-reset-submit",
          eventCategory: "Password Recover and Reset",
          eventAction: "Password Reset Submit",
          eventLabel: "User",
          eventValue: "",
          noninteraction: false
        });

        await this.$store.dispatch("user/updatePassword", {
          id: this.user.id,
          body: this.form
        });
        this.isEditing = false;
        this.hasError = false;
        this.showSnackbar = true;
        this.setDefaultUser();
        this.$refs.passwordInput.setIsEditing(false);

        this.$gtm.trackEvent({
          event: "user-password-reset-success",
          eventCategory: "Password Recover and Reset",
          eventAction: "Password Reset Success",
          eventLabel: "User",
          eventValue: "",
          noninteraction: false
        });
      } catch (error) {
        this.hasError = true;
        this.showSnackbar = true;
        this.reset();
        this.$refs.passwordConfirmationInput.setIsEditing(true);
      }
    },

    setDefaultUser() {
      (this.form.password = "motdepasse"),
        (this.form.password_confirmation = "");
    }
  },

  watch: {
    isEditing: {
      handler: function(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.reset();
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.setDefaultUser();
  }
};
</script>

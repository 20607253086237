<template>
  <div class="w-full">
    <header
      v-if="title && subtitle"
      class="pb-4 text-left text-gray-smoke border-b border-gray-cloud lg:pt-0 lg:border-b-2 lg:border-gray-wall"
    >
      <h4 v-if="title" class="hidden font-lato text-xl lg:block">
        {{ title }}
      </h4>
      <h5 v-if="subtitle" class="font-sans text-sm" v-html="subtitle"></h5>
    </header>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsContent",

  props: {
    title: {
      type: String
    },

    subtitle: {
      type: String
    }
  }
};
</script>

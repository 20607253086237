var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SettingsContentItem',{attrs:{"button":true,"label":"Nom"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('SettingsContentItem',{attrs:{"button":true,"label":"Prénom"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('SettingsContentItem',{attrs:{"type":"email","label":"Adresse contact"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('SettingsContentItem',{attrs:{"button":true,"label":"Numéro contact"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('SettingsContentItem',{attrs:{"label":"Statut du compte"}},[_c('div',{staticClass:"flex-1 flex flex-row-reverse mr-6"},[_c('div',[_c('Toggle',{attrs:{"checked":_vm.form.active},on:{"input":_vm.changeStatus}})],1)])]),_c('Snackbar',_vm._b({model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},'Snackbar',_vm.snackbarProps,false)),_c('Modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],attrs:{"type":_vm.form.active ? 'success' : 'danger',"title":_vm.form.active
        ? _vm.$t('pages.settings.general.enable_title')
        : _vm.$t('pages.settings.general.disable_title'),"actions":{
      cancelVisible: true,
      cancelLabel: 'Annuler',
      actionVisible: true,
      actionLabel: _vm.form.active
        ? _vm.$t('pages.settings.general.enable_action')
        : _vm.$t('pages.settings.general.disable_action'),
      backdropClickEnabled: false
    }},on:{"action":function($event){return _vm.save()},"cancel":function($event){return _vm.cancelActive()}}},[_vm._v(" "+_vm._s(_vm.form.active ? _vm.$t("pages.settings.general.enable_label") : _vm.$t("pages.settings.general.disable_label"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Modal
    v-show="showModal"
    @action="onModalValidate"
    @cancel="onModalCancel"
    :actions="{
      actionVisible: true,
      actionLabel: 'Valider',
      cancelVisible: true,
      cancelLabel: 'Annuler',
      backdropClickEnabled: false,
      actionDisabled: false
    }"
  >
    <div class="font-bold">
      Merci de nous avoir communiqué votre numéro de téléphone.
    </div>
    <div class="font-bold mt-2">
      A quel moment préférez-vous être contacté(e) ?
    </div>
    <div class="flex flex-col w-full md:w-3/4 mx-auto my-4">
      <div class="flex flex-row">
        <div class="w-1/3 m-2"></div>
        <div class="w-1/3 m-2 font-bold">Semaine</div>
        <div class="w-1/3 m-2 font-bold">Samedi</div>
      </div>
      <div class="flex flex-row">
        <div class="w-1/3 m-2 p-2 font-bold">En<br />Journée</div>
        <div
          class="w-1/3 m-2 cursor-pointer"
          :class="{
            checked: slots.week.day,
            unchecked: !slots.week.day
          }"
          @click="slots.week.day = !slots.week.day"
        >
          <Icon
            :name="slots.week.day ? 'phone' : 'times'"
            size="lg"
            class="mt-5"
          />
        </div>
        <div
          class="w-1/3 m-2 cursor-pointer"
          :class="{
            checked: slots.saturday.day,
            unchecked: !slots.saturday.day
          }"
          @click="slots.saturday.day = !slots.saturday.day"
        >
          <Icon
            :name="slots.saturday.day ? 'phone' : 'times'"
            size="lg"
            class="mt-5"
          />
        </div>
      </div>
      <div class="flex flex-row">
        <div class="w-1/3 m-2 p-2 font-bold">Le<br />Midi</div>
        <div
          class="w-1/3 m-2 cursor-pointer"
          :class="{
            checked: slots.week.midday,
            unchecked: !slots.week.midday
          }"
          @click="slots.week.midday = !slots.week.midday"
        >
          <Icon
            :name="slots.week.midday ? 'phone' : 'times'"
            size="lg"
            class="mt-5"
          />
        </div>
        <div
          class="w-1/3 m-2 cursor-pointer"
          :class="{
            checked: slots.saturday.midday,
            unchecked: !slots.saturday.midday
          }"
          @click="slots.saturday.midday = !slots.saturday.midday"
        >
          <Icon
            :name="slots.saturday.midday ? 'phone' : 'times'"
            size="lg"
            class="mt-5"
          />
        </div>
      </div>
      <div class="flex flex-row">
        <div class="w-1/3 m-2 p-2 font-bold">Après<br />18h</div>
        <div
          class="w-1/3 m-2 cursor-pointer"
          :class="{
            checked: slots.week.evening,
            unchecked: !slots.week.evening
          }"
          @click="slots.week.evening = !slots.week.evening"
        >
          <Icon
            :name="slots.week.evening ? 'phone' : 'times'"
            size="lg"
            class="mt-5"
          />
        </div>
        <div
          class="w-1/3 m-2 cursor-pointer"
          :class="{
            checked: slots.saturday.evening,
            unchecked: !slots.saturday.evening
          }"
          @click="slots.saturday.evening = !slots.saturday.evening"
        >
          <Icon
            :name="slots.saturday.evening ? 'phone' : 'times'"
            size="lg"
            class="mt-5"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SettingsPhoneTimeSlots",

  data() {
    return {
      showModal: false,
      slots: {
        week: {
          day: true,
          midday: true,
          evening: true
        },
        saturday: {
          day: true,
          midday: true,
          evening: true
        }
      }
    };
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState("user", ["user"])
  },

  methods: {
    async save() {
      try {
        await this.$store.dispatch("user/update", {
          id: this.user.id,
          body: {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            email: this.user.email,
            phone_time_slots: [this.slots]
          }
        });
      } catch (error) {
        console.error("save user phone_time_slots error");
      }
    },
    onModalValidate() {
      this.save();
      this.showModal = false;
      this.$parent.showPhoneTimeSlot = false;
    },
    onModalCancel() {
      this.onModalValidate();
    }
  },

  mounted() {
    if (
      this.user.phone_time_slots !== null &&
      this.user.phone_time_slots.length > 0
    ) {
      this.slots = this.user.phone_time_slots[0];
    }
  },

  watch: {
    show: function(newValue) {
      this.showModal = newValue;
    }
  }
};
</script>

<style scoped>
.checked {
  background-color: #2ac76928;
  color: #2ac769;
}
.unchecked {
  background-color: #fb4e4e2c;
  color: #fb4e4e;
}
</style>

<template>
  <label
    v-if="visible"
    class="
      flex flex-col
      items-start
      py-2
      font-sans
      lg:flex-row
      lg:items-center
      lg:h-12
      border-b border-gray-cloud
    "
  >
    <div class="w-48 text-base text-left text-gray-smoke">
      {{ label }}
    </div>
    <div
      class="
        flex-1 flex flex-row
        items-center
        h-full
        text-sm text-left text-gray-elephant 
      "
    >
      <slot>
        <input
          class="w-full h-full px-1 bg-transparent outline-none input"
          :class="isEditing ? 'bg-white' : ''"
          :type="type"
          :placeholder="placeholder !== undefined ? placeholder : label"
          :value="value"
          :disabled="!isEditing"
          @input="$emit('input', $event.target.value)"
        />
      </slot>
    </div>
    <Button
      v-if="button && !isEditing"
      appearance="invert"
      size="sm"
      @click="edit()"
      >Modifier</Button
    >
    <Button
      v-if="button && isEditing"
      :disabled="!canValidate"
      appearance="invert"
      size="sm"
      @click="save()"
      >Valider</Button
    >
  </label>
</template>

<script>
export default {
  name: "SettingsContentItem",

  data() {
    return {
      isEditing: false
    };
  },

  model: {
    prop: "value",
    event: "input"
  },

  props: {
    disabled: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      default: "text"
    },

    value: {
      type: String
    },

    label: {
      type: String
    },

    placeholder: {
      type: String
    },

    button: {
      type: Boolean,
      default: false
    },

    visible: {
      type: Boolean,
      default: true
    },

    canValidate: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    edit() {
      this.isEditing = true;
      this.$parent.isEditing = true;
    },

    save() {
      this.isEditing = false;
      this.$parent.save();
    },

    setIsEditing(value) {
      this.isEditing = value;
    }
  },

  mounted() {
    this.isEditing = !this.disabled;
  }
};
</script>
<style scoped>
.input:disabled {
  color: #52575c;
}
</style>
